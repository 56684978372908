<template>
  <v-dialog
    v-model="open"
    width="1800"
    @click:outside="$emit('closed')"
    @keydown.esc="$emit('closed')"
  >
    <v-card>
      <v-card-title class="card-title elevation-3">
        <span class="mr-3">
          Возврат товара
        </span>
        <v-chip class="mr-3" small :color="refundStatusColorEnums[refund.status]">
          {{ refundStatusEnums[refund.status] }}
        </v-chip>
        <span> №{{ refund.refundId }} от {{ refund.refundDate | format }} </span>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('closed')">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row class="mt-3">
          <v-col class="block">
            <v-text-field
              label="Имя"
              readonly
              :value="refund.firstName || 'Не указано'"
              hide-details
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="block">
            <v-text-field
              label="Фамилия"
              readonly
              :value="refund.lastName || 'Не указано'"
              hide-details
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="block">
            <v-text-field
              label="Телефон"
              readonly
              :value="formatPhone(refund.phone)"
              hide-details
            />
          </v-col>
        </v-row>
        <v-row>
          <crm-table :headers="headers" :data="items" :config="config">
            <template #last-slot>
              <v-btn
                v-if="
                  (isWarehouseManage || isPickupPointManager) &&
                    ['CONFIRMED', 'ACCEPTED', 'FINISHED'].includes(refund.status)
                "
                icon
                @click="print()"
              >
                <v-icon>mdi-printer</v-icon>
              </v-btn>
            </template>
          </crm-table>
        </v-row>
        <v-row class="my-4">
          <h4 class="pl-4">Файлы</h4>
        </v-row>
        <v-row>
          <div v-for="(image, index) in imageList" :key="index" class="mb-4">
            <img
              style="max-height:200px;
              max-width:200px;
              margin-right: 10px"
              :src="image.url || 'https://picsum.photos/id/237/200/300'"
            />
          </div>
        </v-row>
        <v-row class="my-4">
          <h4 class="pl-4">Пункт выдачи заказа: {{ refund.warehouseName }}</h4>
        </v-row>

        <v-row v-if="refund.status === 'REJECTED'">
          <v-col cols="4">
            <v-textarea
              v-if="refund.lastReason"
              label="Причина отмены возврата"
              readonly
              v-model="refund.lastReason.comment"
              outlined
            />
          </v-col>
        </v-row>
        <v-row v-if="refund.status === 'CONFIRMED'">
          <h4 v-if="refund.lastReason" class="pl-4">Срок возврата: {{ refund.lastReason.returnDate | format }}</h4>
        </v-row>
        <div v-if="refund.status === 'CREATED'">
          <v-row>
            <v-col cols="12">
              <v-form ref="formAction" v-model="validAction" lazy-validation>
                <v-radio-group v-model="action" :rules="formRules" :disabled="isChief">
                  <v-radio label="Подтвердить" color="success" value="confirm"></v-radio>
                  <v-radio label="Отклонить" color="error" value="reject"></v-radio>
                </v-radio-group>
              </v-form>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <div v-if="action === 'reject'">
                <v-textarea
                  label="Укажите причину"
                  v-model="managerReason"
                  :rules="formRules"
                  outlined
                />
              </div>
              <div v-if="action === 'confirm'">
                <v-menu v-model="menu" :close-on-content-click="false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      style="width: 250px"
                      label="Укажите срок возврата"
                      v-model="computedDateFormatted"
                      clearable
                      outlined
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="formRules"
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="returnDate"
                    active-picker="YEAR"
                    :min="
                      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                        .toISOString()
                        .substr(0, 10)
                    "
                    @change="menu = false"
                  ></v-date-picker>
                </v-menu>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-card-actions class="py-5" v-if="!isChief">
        <v-spacer />
        <v-btn plain @click="$emit('closed')" text>ОТМЕНИТЬ</v-btn>
        <v-btn
          v-if="refund.status === 'CREATED'"
          @click="sendForm"
          color="primary"
          class="px-4"
          rounded
          >ОТПРАВИТЬ</v-btn
        >
        <v-btn
          v-if="refund.status === 'CONFIRMED'"
          @click="sendForm"
          color="primary"
          class="px-4"
          rounded
          >ПРИНЯТЬ ТОВАР</v-btn
        >
      </v-card-actions>
    </v-card>
    <div id="printdiv">
      <div id="hidden_div">
        <RefundPrintForm
          :data="items"
          :order-number="refund.orderId.toString()"
          :return-date="refund.returnDate"
        />
      </div>
    </div>
  </v-dialog>
</template>
<script>
import moment from "moment";
import crmTable from "@/components/crm-table/index.vue";
import RefundPrintForm from "@/views/crm/clients/components/RefundPrintForm.vue";
import { mapState } from "vuex";

export default {
  name: "RefundInfo",
  props: {
    refund: {
      type: Object,
      default: () => {}
    },
    open: {
      type: Boolean,
      default: false
    }
  },
  components: {
    RefundPrintForm,
    "crm-table": crmTable
  },
  filters: {
    format(val) {
      if (moment(val).isValid()) {
        return moment(val, "YYYY-MM-DDTHH:mm:ss.sss[Z]").format("DD.MM.YYYY");
      }
      return "";
    }
  },
  computed: {
    ...mapState(["user"]),
    refundStatusEnums() {
      return this.$store.getters["entity/getEntity"]("refundStatusEnums");
    },
    refundStatusColorEnums() {
      return this.$store.getters["entity/getEntity"]("refundStatusColorEnums");
    },
    items() {
      return [
        {
          productName: this.refund.productName,
          productNomenclatureCode: this.refund.productNomenclatureCode,
          productBarcode: this.refund.productBarcode,
          amount: this.refund.amount,
          price: this.refund.price,
          orderId: this.refund.orderId,
          orderCount: this.refund.orderCount,
          reason: this.refund.reason,
          comment: this.refund.comment,
          refundSum: this.refund.refundSum
        }
      ];
    },
    computedDateFormatted() {
      return this.$options.filters.format(this.returnDate);
    },
    refundRequestEnums() {
      return this.$store.getters["entity/getEntity"]("refundRequestEnums");
    },
    isWarehouseManage() {
      return this.user.roles.some(
        role => role === "ROLE_WAREHOUSE_MANAGER" || role === "ROLE_ADMIN"
      );
    },
    isPickupPointManager() {
      return this.user.roles.includes("ROLE_PICKUP_POINT_MANAGER");
    },
    isChief() {
      return this.user.roles.includes("ROLE_CHIEF");
    }
  },
  mounted() {
    this.prepareFiles();
  },
  data() {
    return {
      headers: [
        {
          value: "productName",
          sortable: false,
          text: "Наименование товара",
          type: "defaultItem"
        },
        {
          value: "productNomenclatureCode",
          sortable: false,
          text: "Артикул",
          type: "defaultItem"
        },
        {
          value: "productBarcode",
          sortable: false,
          text: "Штрихкод",
          type: "defaultItem"
        },
        {
          value: "amount",
          sortable: false,
          text: "Кол-во",
          type: "defaultItem"
        },
        {
          value: "price",
          sortable: false,
          text: "Цена",
          type: "defaultItem"
        },
        {
          value: "refundSum",
          sortable: false,
          text: "Сумма возврата",
          type: "defaultItem"
        },
        {
          value: "orderId",
          sortable: false,
          text: "№ заказа",
          type: "defaultItem"
        },
        {
          value: "orderCount",
          sortable: false,
          text: "Вернуть",
          type: "defaultItem"
        },
        {
          value: "reason",
          sortable: false,
          text: "Причина",
          type: "enums",
          enums: "refundRequestEnums"
        },
        {
          value: "comment",
          sortable: false,
          text: "Комментарий",
          type: "defaultItem"
        }
      ],
      config: {
        hideToolbar: false,
        pageable: false,
        hidePagination: true,
        titleLabel: "Товары"
      },
      imageList: [],
      warehouse: "",
      validAction: true,
      formRules: [v => !!v || "Обязательное поле"],
      action: null,
      managerReason: "",
      returnDate: null,
      menu: false
    };
  },
  methods: {
    formatPhone(str) {
      const cleaned = `${str}`.replace(/\D/g, "");
      const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);
      if (match) {
        const intCode = match[1] ? "+" : "";
        return [intCode, match[1], " ", match[2], " ", match[3], " ", match[4], " ", match[5]].join(
          ""
        );
      }
      return `${str}`;
    },
    async prepareFiles() {
      // eslint-disable-next-line no-restricted-syntax
      for await (const file of this.refund.imageUrlList) {
        const imageUrl = await this.getImageByPath(file);
        this.imageList.push({
          file: null,
          url: imageUrl,
          path: file.path
        });
      }
    },
    async getImageByPath(path) {
      // eslint-disable-next-line no-return-await
      return await this.$image
        .get(`/image/api/image/${encodeURIComponent(path)}`)
        .then(res => res.data);
    },
    sendForm() {
      if (this.refund.status === "CREATED") {
        if (this.$refs.formAction.validate()) {
          if (this.action === "reject") {
            this.returnDate = null;
            this.$api
              .post("/api/v1/crm/refund/reject", {
                comment: this.managerReason,
                id: this.refund.refundId,
                productId: this.refund.productId
              })
              .finally(() => {
                this.$emit("closed");
              });
          }
          if (this.action === "confirm") {
            this.managerReason = "";
            this.$api
              .post("/api/v1/crm/refund/confirmed", {
                returnDate: this.returnDate,
                id: this.refund.refundId,
                productId: this.refund.productId
              })
              .finally(() => {
                this.$emit("closed");
              });
          }
        }
      }
      if (this.refund.status === "CONFIRMED") {
        this.$api
          .post("/api/v1/crm/refund/accepted", {
            id: this.refund.refundId,
            productId: this.refund.productId
          })
          .finally(() => {
            this.$emit("closed");
          });
      }
    },
    async print() {
      try {
        const myPrintContent = document.getElementById("printdiv").innerHTML;
        console.log(this.refund, "this.refund");
        const params = `modal=yes, toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=800, height=600, top=100, left=100`;
        const myPrintWindow = window.open("", "_blank", params);
        let refundDate = moment(this.refund.refundDate, "YYYY-MM-DD HH:mm:ss.sss[Z]").format(
          "DD.MM.YYYY"
        );
        let title = `Возврат №${this.refund.refundId} товар ${
          this.refund.productName
        } ${refundDate.toString()}`;
        myPrintWindow.document.write(
          `<html>
            <head>
                <style>
                .container {
                  width: 100%;
                  padding: 12px;
                  margin-right: auto;
                  margin-left: auto;
                }

                .row {
                  display: flex;
                  flex-wrap: wrap;
                  flex: 1 1 auto;
                  margin: -12px;
                }
                .row + .row {
                  margin-top: 12px;
                }

                .col {
                  width: 100%;
                  padding: 12px;
                }

                .col {
                  flex-basis: 0;
                  flex-grow: 1;
                  max-width: 100%;
                }

                .flex-grow-0 {
                  flex-flow: 0 !important;
                }

                .flex-grow-1 {
                  flex-flow: 1 !important;
                }

                .text-center {
                  text-align: center;
                }

                .text-end {
                  text-align: end;
                }

                .mx-auto {
                  margin: 0 auto;
                }

                .my-table {
                  width: 100%;
                  border-collapse: collapse;
                }

                .my-table th, td {
                  border: 1px solid #000;
                  text-align: center;
                  padding-top: 15px;
                  padding-bottom: 15px;
                }
                </style>
                <title>${title}</title>
            </head>
            <body>${myPrintContent}</body>
        </html>`
        );
        myPrintWindow.document.getElementById("hidden_div").style.display = "block";

        myPrintWindow.document.close();
        setTimeout(() => {
          myPrintWindow.focus();
          myPrintWindow.print();
          myPrintWindow.close();
        }, 200);

        return false;
      } catch (e) {
        console.log(e, " ??? ");
      }
    }
  }
};
</script>
<style scoped>
.block {
  max-width: 200px;
}
#hidden_div {
  display: none;
}
</style>
