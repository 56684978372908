<template>
  <v-dialog
    v-model="dialogInfo"
    @click:outside="onClose"
    @keydown.esc="onClose"
    width="1200px"
    ref="dialogRef"
  >
    <v-card>
      <v-card-title>
        Возврат товара
      </v-card-title>
      <v-divider class="elevation-3"></v-divider>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation @submit="getClientRefunds">
          <v-row class="pt-8">
            <v-col class="py-0" cols="2">
              <v-subheader>Телефон</v-subheader>
            </v-col>
            <v-col class="py-0" cols="6">
              <v-text-field
                v-model="phoneNumber"
                placeholder="Пример: 7771234567"
                prefix="+7"
                id="phoneNumber"
                name="phoneNumber"
                :rules="phoneRules"
                :loading="loading"
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col class="py-0" cols="2">
              <v-btn fab dark x-small color="primary" type="submit">
                <v-icon dark>
                  mdi-magnify
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
        <div v-if="list && list.length">
          <v-form
            ref="requestForm"
            v-model="requestValid"
            lazy-validation
            @submit="onRequestCreate"
          >
            <v-row>
              <v-col class="block">
                <v-text-field
                  label="Имя"
                  v-model="refundRequest.firstName"
                  :rules="formRules"
                  hide-details
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="block">
                <v-text-field
                  label="Фамилия"
                  v-model="refundRequest.lastName"
                  :rules="formRules"
                  hide-details
                />
              </v-col>
            </v-row>
            <v-row class="pa-4 pb-2">
              <h4>Данные документа, удостоверяющего личность</h4>
            </v-row>
            <v-row>
              <v-col class="block">
                <v-text-field
                  label="№ документа"
                  v-model="refundRequest.docNumber"
                  :rules="formRules"
                  @keypress="onlyNumber"
                  dense
                  hide-details
                />
              </v-col>
              <v-col class="block" cols="4">
                <v-menu v-model="menu1" :close-on-content-click="false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      label="Дата выдачи"
                      :value="getFormattedDate()"
                      clearable
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="formRules"
                      dense
                      hide-details
                      @click:clear="refundRequest.docDate = null"
                    />
                  </template>
                  <v-date-picker
                    v-model="refundRequest.docDate"
                    active-picker="YEAR"
                    :max="
                      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                        .toISOString()
                        .substr(0, 10)
                    "
                    min="1991-12-16"
                    @change="menu1 = false"
                  />
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="block-2">
                <v-text-field
                  label="Кем выдано"
                  v-model="refundRequest.docIssuedBy"
                  :rules="formRules"
                  dense
                  hide-details
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="block" cols="2">
                <v-select
                  label="Выберите склад"
                  v-model="warehouseId"
                  :items="warehouses"
                  item-text="name"
                  item-value="id"
                  :rules="formRules"
                />
              </v-col>
            </v-row>
          </v-form>
        </div>
        <v-row v-else-if="userNotFound" class="my-6">
          <v-col align="center">
            <p>{{ userNotFound }}</p>
          </v-col>
        </v-row>
        <v-row v-else-if="listMeta.empty && !loading" class="my-6">
          <v-col align="center">
            <p>Заказанные товары отсутствуют, либо срок возврата истек</p>
          </v-col>
        </v-row>

        <v-row class="mt-2">
          <v-col>
            <crm-table-pageable-new
              v-if="refundRequest.userId"
              id="productTable"
              :headers="headersOrders"
              :data="tableList || []"
              @handleActions="onItemClick"
              :config="config"
              :loaded-options="options"
              :total-elements="listMeta.totalElements"
              :total-pages="listMeta.totalPages"
              item-key="id"
              @onChangeData="onChangeData"
            >
              <template #first-slot>
                <v-slide-y-transition>
                  <v-text-field
                    v-if="searchActive"
                    style="max-width: 300px"
                    class="mr-2"
                    label="Поиск по номеру заказа"
                    v-model="searchOrderId"
                    dense
                    hide-details
                  />
                </v-slide-y-transition>
                <v-btn v-if="!searchActive" @click="searchActive = true" class="fa" fab>
                  <v-icon>
                    mdi-magnify
                  </v-icon>
                </v-btn>
                <v-btn
                  v-else
                  depressed
                  fab
                  class="fa"
                  text
                  @click.stop="(searchActive = false), (searchOrderId = '')"
                >
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </template>
            </crm-table-pageable-new>
          </v-col>
        </v-row>
        <v-row v-if="refundRequest.items.length" class="">
          <v-col>
            <p>
              Количество возвращаемых товаров: <b>{{ getRefundSum(true) }}</b
              >, на сумму <b>{{ (Math.floor(getRefundSum() * 100) / 100).toFixed(0) }}</b> тенге.
            </p>
          </v-col>
        </v-row>
        <v-row class="">
          <v-col class="d-flex justify-space-around" cols="5">
            <v-btn @click="$emit('onDialogClose')" text>ОТМЕНИТЬ</v-btn>
            <v-btn @click="onRequestCreate" color="primary" rounded>
              ОФОРМИТЬ ВОЗВРАТ
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import crmTablePageableNew from "@/components/crm-table-pageable-new/index.vue";
import {
  getRefundTemplateTop,
  tableHeader,
  refundTemplateFooter,
  refundTemplateStyle
} from "@/utils/helper";

export default {
  name: "CreateRefundDialog",
  props: ["dialogInfo"],
  components: {
    "crm-table-pageable-new": crmTablePageableNew
  },
  mounted() {
    this.initDictionaries();
  },
  computed: {
    refundRequestEnums() {
      return this.$store.getters["entity/getEntity"]("refundRequestEnums");
    },
    tableList() {
      function fuzzyMatch(pattern, str) {
        if (!pattern) return true;
        pattern =
          ".*" +
          pattern
            ?.toString()
            .split("")
            .join(".*") +
          ".*";
        const re = new RegExp(pattern);
        return re.test(str);
      }
      if (this.list) {
        return this.list.filter(v => fuzzyMatch(this.searchOrderId, v.orderId)) || [];
      }

      return [];
    }
  },
  data() {
    return {
      searchActive: false,
      searchOrderId: "",
      menu1: false,
      headersOrders: [
        {
          value: "nameRu",
          sortable: false,
          text: "Наименование товара",
          type: "defaultItem"
        },
        {
          value: "productNomenclatureId",
          sortable: false,
          text: "Артикул",
          type: "defaultItem"
        },
        {
          value: "barcode",
          sortable: false,
          text: "Штрихкод",
          type: "defaultItem"
        },
        {
          value: "amount",
          sortable: false,
          text: "Кол-во",
          type: "defaultItem"
        },
        {
          value: "price",
          sortable: false,
          text: "Стоимость",
          type: "defaultItem"
        },
        {
          value: "orderId",
          sortable: false,
          text: "№ заказа",
          type: "defaultItem"
        },
        {
          value: "amountSend",
          sortable: false,
          text: "Вернуть",
          type: "tableNumberField"
        },
        {
          value: "reason",
          sortable: false,
          text: "Причина",
          type: "tableSelect",
          width: "300px",
          enums: "refundRequestEnums"
        }
      ],
      phoneRules: [
        v => !!v || "Укажите номер телефона",
        v => /^\d{10}$/.test(v) || "Номер телефона должен состоять из 10 цифр"
      ],
      formRules: [v => !!v || "Обязательное поле"],
      valid: true,
      requestValid: true,
      loading: false,
      config: {
        add: false,
        search: false,
        pageable: true
      },
      options: {
        page: 1,
        size: 10,
        phoneNumber: ""
      },
      userNotFound: null,
      phoneNumber: "",
      list: null,
      listMeta: {
        empty: false,
        totalElements: 0,
        totalPages: 0
      },
      refundRequest: {
        docDate: null,
        docIssuedBy: "",
        docNumber: "",
        fullName: "",
        items: [],
        userId: "",
        firstName: "",
        lastName: ""
      },
      warehouseId: null,
      warehouses: []
    };
  },

  methods: {
    getRefundSum(isAmount = false) {
      return this.refundRequest.items.reduce(
        (acc, cur) => acc + (isAmount ? 1 : cur.price) * cur.amountSend,
        0
      );
    },
    getOrderIds() {
      const ids = this.refundRequest.items.map(item => item.orderId);
      const uniq = [...new Set(ids)];
      return uniq.join(", ");
    },
    getFormattedDate() {
      const { docDate } = this.refundRequest;
      return docDate ? moment(docDate).format("DD.MM.YYYY") : "";
    },
    onlyNumber(event) {
      const { keyCode } = event;
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      }
    },
    onChangeData(options) {
      let resultOptions = { ...options };
      if (!Object.values(options).length) {
        resultOptions = { ...this.options };
      }

      this.options = { ...resultOptions };
      this.getClientRefunds();
    },
    getClientRefunds(e) {
      if (e) e.preventDefault();
      if (!this.validate()) return;
      this.userNotFound = null;

      this.loading = true;
      this.$api
        .get("/api/v1/crm/refund/search/product", {
          params: {
            page: this.options.page - 1,
            size: this.options.size,
            phoneNumber: `7${this.phoneNumber}`
          }
        })
        .then(response => {
          const { content, ...other } = response;
          /** custom id to prevent duplicate key */
          this.list = content.map(item => {
            const selProduct =
              this.refundRequest.items.length &&
              this.refundRequest.items.find(
                x => x.productId === item.productId && x.orderId === item.orderId
              );
            const amountSend = selProduct ? selProduct.amountSend : 0;
            return Object.assign(item, {
              id: `${item.productId}${item.orderId}`,
              disabled: item.amount === 0,
              amountSend,
              editing: true,
              reason: null,
              rules: {
                amountSend: [v => v <= item.amount || "Возращаемое кол-во больше фактического"]
              }
            });
          });
          this.listMeta = { ...other };
          if (!this.refundRequest.userId) {
            this.getUserIdByPhone();
          }
        })
        .catch(error => {
          this.$store.dispatch("tools/setSnackbar", {
            type: "error",
            message: error.response.data.error || "Ошибка!"
          });
          if (error.response.data.error === "user.phone.not-found") {
            this.userNotFound = "Покупатель с данным номером телефона не найден";
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getUserIdByPhone() {
      this.$api
        .get(`/api/v1/crm/users/by-phone/7${this.phoneNumber}`)
        .then(res => {
          /** ФИО не требуется загружать.
           * Собираем имя и фамилию в строку. Если нет ни того, ни другого, строку "опустошаем" */
          const fullname = `${res.lastName || ''} ${res.firstName || ''}`.trim();
          this.refundRequest = {
            ...this.refundRequest,
            ...res,
            userId: res.id,
            fullName: this.refundRequest.fullName || fullname || res.userName || '',
          };
        })
        .catch(error => {
          this.$store.dispatch("tools/setSnackbar", {
            type: "error",
            message: error.response.data.error || "Ошибка!"
          });
        });
    },
    validate() {
      return this.$refs.form.validate();
    },
    validateRequest() {
      return this.$refs.requestForm && this.$refs.requestForm.validate();
    },
    /** В items добавляются товары при выборе причины (reason),
     * и соответственно, удаляются при отмене выбора причины.
     * Если изменяется количество возвращаемого товара,
     * изменения записываются в массив items. */
    onItemClick(element) {
      const isTableSelect = element.type === "tableSelect";
      if (isTableSelect || element.type === "tableNumberField") {
        const { value } = element.event;
        const { productId, orderId } = element.item;
        const product = {
          ...element.item,
          [isTableSelect ? "reason" : "amountSend"]: value
        };
        /** FIXME: Некорректно считает, необходимо проверить данное условие */
        const pIndex = this.refundRequest.items.findIndex(
          item => item.productId === productId && item.orderId === orderId
        );

        if (product.reason === null && parseInt(product.amountSend, 10) === 0) {
          this.refundRequest.items.splice(pIndex, 1);
          return;
        }
        if (pIndex !== -1) {
          this.refundRequest.items.splice(pIndex, 1, product);
        } else {
          this.refundRequest.items.push(product);
        }

        // this.onItemsSelect(arr);
      }
    },
    onRequestCreate(e) {
      e.preventDefault();
      if (!this.validateRequest()) {
        document.getElementById("phoneNumber").scrollIntoView({ behavior: "smooth" });
        return;
      }

      if (!this.refundRequest.items.length) {
        this.$store.dispatch("tools/setSnackbar", {
          type: "error",
          message: "Ни один товар не выбран"
        });
        return;
      }
      function validateItems({ reason, amount, amountSend }) {
        return !reason || +amountSend === 0 || +amountSend > amount;
      }
      const noReasonItem = this.refundRequest.items.find(validateItems);
      if (noReasonItem) {
        this.$store.dispatch("tools/setSnackbar", {
          type: "error",
          message: `В товаре ${noReasonItem.nameRu} указаны некорректные данные, либо ошибка`
        });
        document.getElementById("productTable").scrollIntoView({ behavior: "smooth" });
        return;
      }

      this.printTemplate();
      this.postRefund();
    },
    postRefund() {
      this.$loading(true);
      const omittedItems = this.refundRequest.items.map(
        ({ amountSend, orderId, productId, reason }) => ({
          amount: amountSend,
          orderId,
          productId,
          reason
        })
      );
      const body = { ...this.refundRequest, items: omittedItems, warehouseId: this.warehouseId };

      this.$api
        .post("/api/v1/crm/refund", body)
        .then(() => {
          this.$store.dispatch("tools/setSnackbar", {
            type: "success",
            message: "Заявка на возврат создана успешно"
          });
          this.onClose();
        })
        .finally(() => {
          this.$loading(false);
        });
    },
    onClose() {
      this.list = null;
      this.listMeta = {
        empty: false,
        totalElements: 0,
        totalPages: 0
      };
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      if (this.$refs.requestForm) {
        this.$refs.requestForm.reset();
      }
      this.refundRequest = {
        docDate: null,
        docIssuedBy: "",
        docNumber: "",
        fullName: "",
        items: [],
        userId: ""
      };
      this.$emit("onDialogClose");
    },
    printTemplate() {
      const { fullName, docNumber, docIssuedBy } = this.refundRequest;
      const tableContent = this.refundRequest.items
        .map(
          (item, index) =>
            '<tr style="height: 18px;">\n' +
            `<td>${index + 1}</td>\n` +
            `<td>${item.nameRu}</td>\n` +
            `<td>${item.productNomenclatureId}</td>\n` +
            `<td>${item.barcode}</td>\n` +
            `<td>${item.amountSend}</td>\n` +
            `<td>${(Math.floor(item.price * 100) / 100).toFixed(0)}</td>\n` +
            `<td>${this.refundRequestEnums[item.reason]}</td>\n` +
            "</tr>"
        )
        .join("");
      const printWindow = window.open("about:blank", "_blank");
      printWindow.location = 0;
      printWindow.document.write(`<html>${refundTemplateStyle}<body>`);
      // printWindow.document.write(refundTemplateTop);
      printWindow.document.write(
        getRefundTemplateTop(
          fullName,
          docNumber,
          docIssuedBy,
          this.getFormattedDate(),
          this.getOrderIds()
        )
      );
      printWindow.document.write('<table cellpadding="4"><tbody>');
      printWindow.document.write(tableHeader);
      printWindow.document.write(tableContent);
      printWindow.document.write("</tbody></table>");
      printWindow.document.write(refundTemplateFooter);
      printWindow.document.write("</body></html>");
      printWindow.document.close();

      printWindow.focus();
      printWindow.print();
      printWindow.close();
      return true;
    },
    initDictionaries() {
      this.$dictionaryService.get("/warehouse").then(res => {
        this.warehouses = [...res.data];
      });
    }
  }
};
</script>

<style scoped>
.block {
  max-width: 200px;
}
.block-2 {
  max-width: 400px;
}
</style>
