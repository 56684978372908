<template>
  <div class="print-form">
    <v-container style="max-width: 90%">
      <div class="row my-4">
        <div class="col text-center">
          <img class="mx-auto" :src="require(`@/assets/logo_black.png`)" />
        </div>
      </div>
      <div class="row my-4">
        <div class="col flex-grow-0">
          <span style="white-space: nowrap">
            От Ф.И.О. _______________________________________________________________
          </span>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col text-center">
          <h2>Заявление на возврат товара.</h2>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <span>В «Clever» был приобретен товар по заказу №{{ orderNumber }}</span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <table class="my-table">
            <thead>
              <tr>
                <th>№</th>
                <th>Наименование товара</th>
                <th>Артикул</th>
                <th>Штрихкод</th>
                <th>Количество</th>
                <th>Стоимость</th>
                <th>Причина возврата</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in data" :key="item.id">
                <td>1</td>
                <td>{{ item.productName }}</td>
                <td>{{ item.productNomenclatureCode }}</td>
                <td>{{ item.productBarcode }}</td>
                <td>{{ item.amount }}</td>
                <td>{{ item.price }}</td>
                <td>{{ refundRequestEnums[item.reason] }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row my-10">
        <div class="col">
          <span>Прошу вернуть мне деньги следующим способом (выбрать):</span>
          <ul>
            <li>На банковский счет</li>
            <li>На платежную карту</li>
          </ul>
        </div>
      </div>
      <div class="row my-4">
        <div class="col">
          <span>Дата {{ returnDate }}</span>
        </div>
        <div class="col text-end">
          <span>Подпись ___________________</span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <span>
            Внимание! Вы можете вернуть товар в течение 14 дней после получения заказа. Возврат
            товара возможен в случае, если сохранены его товарный вид, потребительские свойства, все
            ярлыки и бирки, а также документ, подтверждающий факт и условия покупки указанного
            товара.
          </span>
        </div>
      </div>
    </v-container>
  </div>
</template>
<script>
export default {
  name: 'RefundPrintForm',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    orderNumber: {
      type: String,
      default: ''
    },
    returnDate: {
      type: String,
      default: ''
    }
  },
  computed: {
    refundRequestEnums() {
      return this.$store.getters['entity/getEntity']('refundRequestEnums');
    },
  },
};
</script>
<style scoped lang="scss">

</style>
