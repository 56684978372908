<template>
  <v-container fluid>
    <crm-table-pageable-new
      class="custom-refunds-table"
      :headers="headers"
      :data="clients"
      :config="config"
      :loaded-options="options"
      :total-elements="totalElements"
      :total-pages="totalPages"
      :available-filters.sync="availableFilters"
      :filters-dictionaries="filtersDictionaries"
      :extraActions="extraActions"
      addButtonTitle="Возврат"
      @onChangeData="onChangeData"
      @handleActions="handle"
      @extraAction="handleExtraAction"
    >
    </crm-table-pageable-new>
    <create-refund-dialog
      :dialogInfo="dialogInfo"
      @onDialogClose="closeDialog"
    />
    <refund-info
      v-if="showRefundInfo"
      :refund="refundItem"
      :open="showRefundInfo"
      @closed="closeRefundInfo"
    />
  </v-container>
</template>

<script>
import moment from 'moment';
import crmTablePageableNew from '@/components/crm-table-pageable-new/index.vue';
import RefundInfo from '@/views/crm/clients/components/RefundInfo.vue';
import CreateRefundDialog from './components/CreateRefundDialog.vue';

export default {
  name: 'refunds',
  components: {
    RefundInfo,
    CreateRefundDialog,
    'crm-table-pageable-new': crmTablePageableNew,
  },
  data() {
    return {
      headers: [
        {
          value: 'refundId', sortable: true, text: '№', type: 'defaultItem', width: '65px',
        },
        {
          value: 'refundDate', sortable: true, text: 'Дата возврата', type: 'datetime',
        },
        {
          value: 'deliveryDate', sortable: true, text: 'Дата доставки', type: 'date',
        },
        {
          value: 'fullNameComputed', sortable: true, text: 'ФИО', type: 'defaultItem',
        },
        {
          value: 'phone', sortable: false, text: 'Телефон', type: 'defaultItem',
        },
        {
          value: 'orderId', sortable: false, text: '№ заказа', type: 'defaultItem',
        },
        {
          value: 'productName', sortable: false, text: 'Наименование товара', type: 'defaultItem',
        },
        {
          value: 'productNomenclatureCode', sortable: false, text: 'Артикул', type: 'defaultItem',
        },
        {
          value: 'productBarcode', sortable: false, text: 'Штрихкод', type: 'defaultItem',
        },
        {
          value: 'amount', sortable: false, text: 'Количество', type: 'defaultItem',
        },
        {
          value: 'price', sortable: false, text: 'Цена', type: 'defaultItem',
        },
        {
          value: 'refundSum', sortable: false, text: 'Сумма возврата', type: 'defaultItem',
        },
        {
          value: 'status', sortable: false, text: 'Статус', type: 'enums', enums: 'refundStatusEnums',
        },
      ],
      dataOrders: [],
      visible: false,
      item: {},
      dialogInfo: false,
      config: {
        add: true,
        search: true,
        pageable: true,
      },
      availableFilters: [
        {
          id: 'refundDate', name: 'Дата возврата', type: 'date', menu: false, active: false,
        },
        {
          id: 'deliveryDate', name: 'Дата доставки', type: 'date', menu: false, active: false,
        },
        {
          id: 'statusList', name: 'Статус заявки на возврат', type: 'select', active: false,
        },
      ],
      filtersDictionaries: {
        statusList: [
          { value: 'CREATED', label: 'Оформлен' },
          { value: 'CONFIRMED', label: 'Подтвержден' },
          { value: 'ACCEPTED', label: 'Принят' },
          { value: 'REJECTED', label: 'Отклонен' },
          { value: 'FINISHED', label: 'Завершен' },
        ],
      },
      clients: [],
      totalElements: 0,
      totalPages: 0,
      url: '/api/v1/crm/refund',
      options: {
        page: 1,
        size: 10,
      },
      refundItem: {},
      showRefundInfo: false,
      extraActions: [
        {
          type: 'reports',
          action: 'export',
          icon: 'mdi-file-excel',
          title: 'Выгрузить в Excel',
        },
      ],
    };
  },
  created() {
    this.onChangeData(this.$route.query);
  },
  methods: {
    onChangeData(options) {
      let resultOptions = { ...options };
      if (!Object.values(options).length) {
        resultOptions = { ...this.options };
      }
      this.$router.replace({
        path: this.$route.path,
        params: {},
        query: resultOptions,
      }).catch(() => {});
      this.options = { ...resultOptions };
      this.getRefunds();
    },
    getRefunds() {
      const { query } = this.$route;
      const formattedUrl = this.url;
      let sort = 'refund.createdDate,desc';
      if (query.sort) {
        let regex;
        let str;
        switch (true) {
          case query.sort.includes('refundDate'):
            regex = /refundDate/gi;
            str = 'refund.createdDate';
            break;
          case query.sort.includes('fullName'):
            regex = /fullName/gi;
            str = 'refund.fullName';
            break;
          case query.sort.includes('refundId'):
            regex = /refundId/gi;
            str = 'refund.id';
            break;
          default: return;
        }
        sort = query.sort.replace(regex, str);
      }
      // refund.createdDate
      this.$loading(true);
      this.$api.get(formattedUrl, {
        params: {
          page: query.page - 1,
          size: query.size,
          search: query.search,
          refundDate: query.refundDate,
          deliveryDate: query.deliveryDate,
          status: query.statusList,
          sort,
        },
      }).then((response) => {
        this.totalElements = response.totalElements;
        this.totalPages = response.totalPages;
        this.clients = response.content.map((el) => ({
          ...el,
          fullNameComputed: `${el.firstName} ${el.lastName}`,
        }));
      }).catch((error) => {
        this.$store.dispatch('tools/setSnackbar', {
          type: 'error',
          message: error.response.data.error || 'Возникла ошибка',
        });
      }).finally(() => {
        this.$loading(false);
      });
    },
    getFormattedDate(date) {
      return date ? moment(date).format('DD.MM.YYYY HH:mm') : 'Нет даты';
    },
    handle(event) {
      if (event.type === 'info') {
        this.showRefundInfo = true;
        this.refundItem = { ...event.item };
      }
      if (event.type === 'create') {
        // Object.assign(this.item, event.item);
        this.dialogInfo = true;
      }
    },
    handleExtraAction(action) {
      if (action === 'export') {
        this.print();
      }
    },
    closeRefundInfo() {
      this.onChangeData(this.$route.query);
      this.showRefundInfo = false;
      this.refundItem = {};
    },
    closeDialog() {
      this.dialogInfo = false;
      this.onChangeData(this.$route.query);
    },
    print() {
      alert('Backend должен сделать апи');
    },
  },
};
</script>

<style>
.custom-refunds-table tr {
  cursor: pointer;
}
</style>
